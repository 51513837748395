export const RouterConfig = {
    MAIN: "/",
    CATALOG: "/catalog",
    PRODUCT: "/product",
    CREATE_OFFER: "/post",
    INSURANCE: "/insurance",
    BLOG: "/blog",
    COMPARE: "/compare",
    FAVORITES: "/saved",
    PRIVACY_POLICY: "/docs/privacy-policy",
    TERMS_OF_USE: "/docs/terms_of_use_LIKVI.pdf"
};